var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "16",
      "cy": "16",
      "r": "16",
      "fill": "#FC3F1D"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M21 23.044h-2.63V10.037h-1.172c-2.147 0-3.272 1.074-3.272 2.677 0 1.819.776 2.661 2.38 3.735l1.324.892-3.802 5.702H11l3.421-5.09c-1.967-1.406-3.074-2.778-3.074-5.092 0-2.892 2.016-4.86 5.833-4.86h3.802v15.04H21v.005Z",
      "fill": "#fff"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }